import React, { useEffect, useMemo, useState } from 'react';

import { format } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import { twMerge } from 'tailwind-merge';

import { CalendarDaysIcon, XMarkIcon } from '@heroicons/react/24/outline';

import api from '../../lib/api';
import { IconButton } from '../shared/IconButton';
import { showToast } from '../shared/Toast';
import { METADATA_MAP } from '../../constants/nylas';

interface SceneCardCalendarEventProps {
  category: 'shootDays' | 'rehearsalDays' | 'scoutLocation';
  sceneCard: any;
  currentEvents: any;
  calendarId: string;
  calendarCategories: any;
  currentUserEmail: string;
  sceneCardUsers: any;
  projectId: string;
}

function FieldLabel({ category }: { category: string }) {
  switch (category) {
    case 'shootDays':
      return <div className="text-sm leading-5 font-medium text-gray-700">Shoot Days</div>;
    case 'rehearsalDays':
      return <div className="text-sm leading-5 font-medium text-gray-700">Rehearsal Day</div>;
    case 'scoutLocation':
      return <div className="text-sm leading-5 font-medium text-gray-700">Scout Location</div>;
    default:
      return null;
  }
}

export function getCalendarCategoryId({ category, calendarCategories }) {
  if (!calendarCategories || !category) return null;

  if (category === 'shootDays') {
    const shootDaysCategory = calendarCategories.find((category) => category.name === 'Shoot Day');
    return shootDaysCategory?.id;
  }

  if (category === 'rehearsalDays') {
    const rehearsalDaysCategory = calendarCategories.find(
      (category) => category.name === 'Rehearsal Day'
    );
    return rehearsalDaysCategory?.id;
  }

  if (category === 'scoutLocation') {
    const scoutLocationCategory = calendarCategories.find(
      (category) => category.name === 'Location Scout'
    );
    return scoutLocationCategory?.id;
  }

  return null;
}

export function filterEventsByCategory({ events, calendarCategories, category }) {
  if (!events || !calendarCategories || !category) return [];

  const categoryId = getCalendarCategoryId({ category, calendarCategories });
  if (!categoryId) return [];
  return events.filter((event) => +event.calendarCategoryId === categoryId);
}

export default function SceneCardCalendarEvent({
  category,
  sceneCard,
  calendarId,
  currentEvents,
  calendarCategories,
  currentUserEmail,
  sceneCardUsers,
  projectId,
}: SceneCardCalendarEventProps) {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const events = filterEventsByCategory({
      calendarCategories,
      events: currentEvents,
      category,
    }).filter((event) => event.allDay); // get only all day single day events
    setEvents(events);
  }, []);

  const categoryId = useMemo(
    () => getCalendarCategoryId({ category, calendarCategories }),
    [category, calendarCategories]
  );

  const onCreateEvent = async (date) => {
    if (loading) return;
    setLoading(true);
    const sceneCardUserList =
      sceneCardUsers?.map((user) => ({
        email: user.email,
      })) || [];
    const result = await api.post(`/projects/${projectId}/calendar/create_event`, {
      event_input: {
        calendar_id: calendarId,
        when: { date: format(date, 'yyyy-MM-dd') },
        title: sceneCard.scene_name,
        participants: [{ email: currentUserEmail }, ...sceneCardUserList],
        metadata: {
          [METADATA_MAP.calendar_category_id]: categoryId?.toString(),
          [METADATA_MAP.scene_card_id]: sceneCard.uuid,
          [METADATA_MAP.organizer_email]: currentUserEmail,
        },
      },
    });
    const event = result?.data;
    if (event) {
      setEvents((prevState) => [...prevState, event]);

      if (result.status !== 200) {
        showToast('Something went wrong', 'danger');
      }
    }
    setLoading(false);
  };

  const onDeleteEvent = async (eventId) => {
    if (loading) return;
    setLoading(true);
    setEvents((prevState) => prevState.filter((event) => event.id !== eventId));
    const result = await api.delete(`/projects/${projectId}/calendar/delete_event/${eventId}`);

    if (result.status !== 200) {
      showToast('Something went wrong', 'danger');
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-2">
      <FieldLabel category={category} />
      <ReactDatePicker
        onSelect={(date) => {
          onCreateEvent(date);
        }}
        dateFormat="MM/dd/yyyy"
        highlightDates={events.map((event) => new Date(event.start + 'T00:00:00'))}
        shouldCloseOnSelect={false}
        placeholderText="Select dates"
        wrapperClassName="react-datepicker-wrapper"
        disabledKeyboardNavigation={true}
        showPopperArrow={false}
        disabled={!calendarId}
        monthsShown={2}
        className={twMerge(
          'outline-none block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-blue-500 text-gray-500'
        )}
        customInput={(<div className="cursor-pointer">Select dates</div>) as any}
        /* @ts-ignore */
        icon={<CalendarDaysIcon className="text-gray-400" />}
      />
      <div className="flex gap-2 flex-wrap">
        {events?.map((event) => {
          return (
            <div key={event.id} className="bg-gray-100 px-2 rounded-md flex items-center gap-2">
              <div className="whitespace-nowrap text-sm leading-5">
                {format(new Date(event.start + 'T00:00:00'), 'MMMM dd')}
              </div>
              <IconButton onClick={() => onDeleteEvent(event.id)}>
                <XMarkIcon className="text-gray-500 w-3 h-3" />
              </IconButton>
            </div>
          );
        })}
      </div>
    </div>
  );
}
